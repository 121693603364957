.mat-snack-bar-container {

  background: #6DB35D !important;
  color: #fff !important;

  &.-error {

    background: #DF645F !important;

  }

  &.-warning {

    background: #dfb05f !important;

  }

  .mat-simple-snackbar {
    span {
      word-break: break-word;
      white-space: normal;
    }
  }
}

.withSignContainerDialog {

  .mat-dialog-container {
    overflow: visible;

  }
}

.emptyContainerDialog {

  .mat-dialog-container {
    background-color: #0000002e;
    padding: 0;
  }

  .mat-dialog-content {
    padding: 0;
    margin: 10px 0 0 0;
    max-height: 80vh;
    border-radius: 5px;
    background-color: #fff;
  }

  .mat-dialog-title {
    margin-bottom: 0;
  }

}

.mat-button-base {
  border-radius: 24px !important;
}

.mat-dialog-container {
  position: relative;

  min-width: 450px;

  @include before(450px) {

    min-width: unset;

  }

  .modal-sign-container {
    position: absolute;
    top: -25px;
    left: 50%;
    width: 46px;
    height: 46px;
    margin: 0 0 0 -23px;
    background-color: #fff;
    border: 2px solid map-get($custom-primary, 400);
    border-radius: 50%;

    .modal-sign {
      line-height: 42px;
      text-align: center;
      font-size: 17px;
      color: map-get($custom-primary, 400);
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }

  .mat-form-field {
    width: 100%;
  }

}

.modal-buttons-basic-group {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.full-widthTags {

  .mat-form-field-infix {
    width: unset;
  }

}

.mat-chip-list {

  .mat-chip-list-wrapper {
    margin: -8px -8px -8px -2px;

    .mat-chip-input {
      margin: 8px;
    }

  }

  .mat-standard-chip {
    font-size: 14px;

    &.mat-chip-with-trailing-icon {
      min-height: 18px;
      margin: 2px;
      padding: 6px 6px 6px 8px;

    }

    .mat-chip-remove.mat-icon {
      width: 12px;
      height: 12px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 6px;
    }

  }

  .overflow-fix {
    height: auto; // overrides default height 1px
    line-height: 1.05em; // keeps chip from "squaring out" at one line
  } 

}

.ngx-mat-timepicker.ng-invalid {

  .mat-form-field-infix {
    color: #ef3f36;
  }

}

.mat-badge-content {
  width: auto !important;
  right: unset !important;
  padding: 0 4px;
  min-width: 22px;
  position: relative !important;
  top: -5px !important;
}

.table-container.-matTableInfinityScroll {

  overflow: auto;

  .table-wrapper {
    max-height: 489px;
  }

  th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
    @include before($d) {
      white-space: nowrap;
    }
  }

}

.angular-editor {

  .select-heading {
    margin-right: -5px;
  }

  .select-font {
    display: none !important;
  }

  ol, ul {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 40px;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  li {
    margin: unset;
    padding: unset;
  }

}

.table-container {
  position: relative;

  .table-wrapper {
    width: 100%;
    overflow: auto;

      &::-webkit-scrollbar {
        height: 3px;
      }
      &::-webkit-scrollbar-track {
        background-color: rgba(37, 37, 37, 0.05);
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: rgb(155, 155, 155);
      }
  }

}

.mat-table {
  width: 100%;

  thead {

    th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
      padding: 0 12px;
    }

  }

  tbody {

    th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
      padding: 0 12px;
    }

    

      @include after($t) {
        th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
          padding: 0 12px;
        }

        td {
          max-width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        td.mat-column-details {
          width: 64px;
        }

    }
  }
}

.mat-button-toggle-group .mat-button-toggle-label-content,
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 37px !important;
}

.mat-form-field {

  &.mat-form-field-appearance-outline {

    &.mat-form-field-can-float.mat-form-field-should-float {

      .mat-form-field-label {
        transform: translateY(-16px) scale(.75) !important;
        width: 133.33333% !important;
      }

    }

    .mat-form-field-flex {
      margin-top: -4px;

      & > .mat-form-field-infix {
        padding: 8px 0 !important;
      }
    }

    .mat-form-field-label-wrapper {
      top: -18px !important;
    }

  }

}

.mat-datepicker-content {
  height: 100%;
}

.multiple-select {
  margin-left: 20px;
  min-width: calc(100% + 42px) !important;
  height: calc(100% + 25px);
}

.cdk-virtual-scroll-content-wrapper {
  width: 100%;
}

.multiline-tooltip {
  white-space: pre-line;
}
