@import "../../../assets/styles/main/material.scss";

html, body {
  height: 100%;
}

body {
  margin: 0;
}

.hideOnLoading {
  visibility: hidden;
}

.root {
  height: 100%;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &-content {
    flex: 1 0 auto;
  }

  .container.-paddingPanelEmit {
    transition: padding-top 0.3s ease-out;
    padding-top: 110px;

    @include before($md) {
      padding-top: 80px;
    }
  }

  app-profile {
    .container.-paddingPanelEmit {
      padding-top: 110px;

      @include before($md) {
        padding-top: 80px;
      }
    }
  }

}

a {
  color: map-get($custom-primary, 400);
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: map-get($custom-primary, 600);
  }
}

.loading-shade {

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

}

.full-width {
  width: 100%;

  .mat-form-field-infix {
    width: unset;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: "color 99999s ease-out, background-color 99999s ease-out" !important;
  -webkit-transition-delay: 99999s !important;
}

.modal-sign-form {
  width: 450px;
  display: flex !important;
  justify-content: space-between;

  @media (max-width: 470px) {
    width: 310px;
    flex-direction: column;
  }

  .modal-sign-subtitle {
    margin-bottom: 10px;
  }

  .modal-sign-radio-group {
    display: flex;
    flex-direction: column;

    .mat-radio-button {
      margin-bottom: 15px;
    }
  }

  &-first-part {
    display: flex !important;
    flex-direction: column;
  }
}

.primary {
  color: map-get($custom-primary, 400);
}

.warn {
  color: map-get($custom-warn, 500);
}

.default {
  color: $common-text-color;
}

.mat-option {
  .mat-spinner {
    margin: 0 auto;
  }
}

.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid #bce8f1;
  border-radius: 4px;
}

ng-scrollbar.scroll-event {

  --scrollbar-size: 6px;
  --scrollbar-thumb-color: gray;
  --scrollbar-padding: 0px;
  --scrollbar-hover-size: 7px;
  --scrollbar-track-color: rgba(0, 0, 0, 0.05);
  --scrollbar-border-radius: 10px;

  max-height: 500px;

}

.ng-scroll-viewport {
  display: flex;
  flex-direction: column;
}
.ng-scroll-content {
  flex: 1;
}

angular-editor {
  line-height: initial;
}

.mat-radio-label-content {
  white-space: normal;
  word-break: break-all;
}

.custom-tooltip {
  font-size: 14px !important;
}

.title-tooltip {
  font-size: 0.8rem !important;
  background: map-get($custom-primary, 300) !important;
}

.multiple-select-btn {
  border-top: 2px solid rgba(0,0,0,0.09);
  height: 52px;
  padding-right: 10px !important;
  position: sticky;
  bottom: 0;
  background-color: #fff;
}

.vertical-scroll {
  overflow: auto;

    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.05);
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: gray;
    }
}

.time-by-location-warning{
  font-size: 0.6rem;
  color: map-get($custom-warn, 500);
}

.mat-option-narrow {
  height: 40px !important;
}

  /* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  width: 8px;
  background: #555; 
}

@keyframes spinner-in-button {
  to {transform: rotate(360deg);}
}

.spinner-in-button:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-top-color: map-get($app-primary, default);
  animation: spinner-in-button .8s linear infinite;
}
