@use '@angular/material' as mat;
@import '@angular/material/theming';

// MATERIAL CONFIG

$common-text-color: rgba(0, 0, 0, .6);

$custom-primary: (
        50: #e5f3fc,
        100: #bee1f9,
        200: #97cef5,
        300: #71bbf0,
        400: #55acee,
        500: #3e9eeb,
        600: #3790de,
        700: #2f7ecb,
        800: #296db9,
        900: #19509c,
        A100: #8c9eff,
        A200: #536dfe,
        A400: #3d5afe,
        A700: #304ffe,
        contrast: (50: $dark-primary-text,
                100: $dark-primary-text,
                200: $dark-primary-text,
                300: $light-primary-text,
                400: $light-primary-text,
                500: $light-primary-text,
                600: $light-primary-text,
                700: $light-primary-text,
                800: $light-primary-text,
                900: $light-primary-text,
                A100: $dark-primary-text,
                A200: $light-primary-text,
                A400: $light-primary-text,
                A700: $light-primary-text,
        )
);

$custom-secondary: (
        50: #f2f8e8,
        100: #ddeec6,
        200: #c7e2a2,
        300: #b0d77c,
        400: #9fce5e,
        500: #8ec540,
        600: #7eb538,
        700: #69a12e,
        800: #568d25,
        900: #316b13,
        A100: #8c9eff,
        A200: #536dfe,
        A400: #3d5afe,
        A700: #304ffe,
        contrast: (50: $dark-primary-text,
                100: $dark-primary-text,
                200: $dark-primary-text,
                300: $light-primary-text,
                400: $light-primary-text,
                500: $light-primary-text,
                600: $light-primary-text,
                700: $light-primary-text,
                800: $light-primary-text,
                900: $light-primary-text,
                A100: $dark-primary-text,
                A200: $light-primary-text,
                A400: $light-primary-text,
                A700: $light-primary-text,
        )
);

$custom-warn: (
        50: #feebee,
        100: #fdccd1,
        200: #ec9899,
        300: #e17172,
        400: #ea5050,
        500: #ef3f36,
        600: #e03535,
        700: #ce2c2f,
        800: #c12528,
        900: #b2181c,
        A100: #8c9eff,
        A200: #536dfe,
        A400: #3d5afe,
        A700: #304ffe,
        contrast: (50: $dark-primary-text,
                100: $dark-primary-text,
                200: $dark-primary-text,
                300: $light-primary-text,
                400: $light-primary-text,
                500: $light-primary-text,
                600: $light-primary-text,
                700: $light-primary-text,
                800: $light-primary-text,
                900: $light-primary-text,
                A100: $dark-primary-text,
                A200: $light-primary-text,
                A400: $light-primary-text,
                A700: $light-primary-text,
        )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.define-palette($custom-primary, 400);
$app-accent: mat.define-palette($custom-secondary);
$app-warn: mat.define-palette($custom-warn);


$app-typography: mat.define-legacy-typography-config($font-family: 'Merriweather',
                $headline: mat.define-typography-level(36px, 40px, 500),
                $body-1: mat.define-typography-level(14px, 20px, 500));

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat.define-light-theme((color: (primary: $app-primary,
                                accent: $app-accent,
                                warn: $app-warn,
                        )));

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($app-typography);`
@include mat.all-legacy-component-typographies($app-typography);
@include mat.legacy-core();
@include mat.all-legacy-component-themes($app-theme);
