/* Supported by all browsers except for IE 9-11 */

/* merriweather-300 */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 300;
  src:
    local(''),
    url($fonts + '/Merriweather-Light.ttf');
  font-display: swap;
}

/* merriweather-300-italic */
@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 300;
  src:
    local(''),
    url($fonts + '/Merriweather-LightItalic.ttf');
  font-display: swap;
}

/* merriweather-regular */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url($fonts + '/Merriweather-Regular.ttf');
  font-display: swap;
}

/* merriweather-italic */
@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url($fonts + '/Merriweather-Italic.ttf');
  font-display: swap;
}

/* merriweather-700 */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url($fonts + '/Merriweather-Bold.ttf');
  font-display: swap;
}

/* merriweather-700italic */
@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url($fonts + '/Merriweather-BoldItalic.ttf');
  font-display: swap;
}

/* merriweather-900 */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 900;
  src:
    local(''),
    url($fonts + '/Merriweather-Black.ttf');
  font-display: swap;
}

/* merriweather-900italic */
@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 900;
  src:
    local(''),
    url($fonts + '/Merriweather-BlackItalic');
  font-display: swap;
}
