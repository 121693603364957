@include before($t) {
    .mat-table[matTableResponsive] {
      .mat-header-row {
        display: flex;
        flex-wrap: wrap;
        height: auto;
        position: sticky;
        top: 0;
        z-index: 2;
  
        th.mat-header-cell {
          display: flex;
          flex: 1;
          align-items: center;
          padding: 1rem 0.5rem;
          justify-content: space-around;
  
          & > .mat-sort-header-container {
            padding-left: 15px;
          }
  
          &:not([mat-sort-header]),
          &.mat-sort-header-disabled,
          &.action-cell-responsive {
            display: none;
          }
        }
      }
  
      .mat-row {
        height: auto;
  
        td.mat-cell:not(.action-cell-responsive) {
          display: grid;
          grid-template-columns: 30% 70%;
          padding: 0.5rem;
          text-align: right;
          word-break: break-word;
  
          & > * {
            grid-column: 2;
          }
  
          &:before {
            content: attr(data-column-name);
            text-align: left;
          }
        }
  
        .action-cell-responsive {
          display: flex;
          justify-content: center;
          padding: 0;
          width: 100%;
  
          & > :is(button, div, a, mat-icon) {
            display: block;
            margin: 0.5rem 0;
            text-align: center;
            width: 100%;
          }
        }
  
        &:last-of-type td:last-of-type {
          border: none;
        }
  
        &:not(:last-of-type) td:last-of-type {
          border-bottom: 1.5rem solid #e6e6e6;
        }
      }
    }  
}
